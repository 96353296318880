import styled from '@emotion/styled';

export const PlaylistTable = styled.table(
  (props) => `
  .play-image {
    display: block;
    border-radius: ${props.theme.size.base * 0.5}px;
    width: ${props.theme.size.base * 9}px;
    height: ${props.theme.size.base * 9}px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .play-image-placeholder {
    position: relative;
    margin-right: ${props.theme.size.base * 2}px;
    width: ${props.theme.size.base * 9}px;
    height: ${props.theme.size.base * 9}px;
    border-radius: ${props.theme.size.base * 0.5}px;
    font-size: ${props.theme.size.base * 1}px;
    line-height: ${props.theme.size.base * 1.5}px;
    padding: ${props.theme.size.base * 0.5}px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    background: ${props.theme.color.tertiary[20]};
    border: 1px solid ${props.theme.color.tertiary[40]};
    color: ${props.theme.color.tertiary[60]};
    transition: color 450ms ease, background 450ms ease, border 450ms ease;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    &:after {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${props.theme.size.base * 8}px;
      height: ${props.theme.size.base * 8}px;
      border-radius: 50%;
      border: 1px solid ${props.theme.color.tertiary[30]};
    }
    &:before {
      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${props.theme.size.base * 4}px;
      height: ${props.theme.size.base * 4}px;
      border-radius: 50%;
      border: 1px solid ${props.theme.color.tertiary[25]};
    }
  }
  .play-song {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[80]};
    transition: color 450ms ease;
  }
  .play-artist {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    font-weight: 600;
    color: ${props.theme.color.primary[50]};
    transition: color 450ms ease;
  }
  .play-release {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    color: ${props.theme.color.foreground[40]};
    transition: color 450ms ease;
  }
  .play-time {
    color: ${props.theme.color.foreground[0]};
    transition: color 450ms ease;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    font-size: ${props.theme.size.fontSize[1]};
    line-height: ${props.theme.size.lineHeight[1]};
  }
  `
);
