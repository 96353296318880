import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { formatRelative, format, lightFormat } from 'date-fns';
import Moment from 'react-moment';
import Badge from '../../components/Badge';
import Header from '../../components/Header';

import ThemeContext from '../../context/ThemeContext';
import RouterTracker from '../../components/RouterTracker';

import placeHolderImage from '../../img/record.jpg';
import JSONPretty from 'react-json-pretty';
import { PlaylistTable } from '../../components/PlaylistTable';
import { Link } from 'gatsby';

var JSONPrettyMon = require('react-json-pretty/dist/monikai');

const PlaylistStyles = styled.div(
  (props) => `
    max-width: ${props.theme.size.base * 128}px;
    margin: 0 auto;
    padding: ${props.theme.size.base * 13}px 
    ${props.theme.size.base * 4}px 
    ${props.theme.size.base * 4}px;
    ${
      (props.breakpoints.lg || props.breakpoints.xl) &&
      `
    padding: ${props.theme.size.base * 8}px;
    `
    };
  `
);
const ShowListItem = styled.tr(
  (props) => `
    background: ${props.theme.color.background[80]};
    background: linear-gradient(
      30deg,
      ${props.theme.color.background[35]},
      ${props.theme.color.secondary[25]}
    );
    &:hover {
      td {
        background: ${props.theme.color.secondary[15]} !important;
      }
    }
    a,
    a:link,
    a:visited {
      color: ${props.theme.color.primary[60]};
      transition: color 450ms ease-in-out;
      &:hover {
        color: ${props.theme.color.secondary[65]};
      }
    }
    td.show-title {
      font-size: ${props.theme.size.fontSize[4]};
      line-height: ${props.theme.size.lineHeight[4]};
      padding-left: ${props.theme.size.base * 6}px;
    }
    .show-image {
      margin-right: -${props.theme.size.base * 5}px;
      display: block;
      border-radius: ${props.theme.size.base}px;
      width: ${props.theme.size.base * 14}px;
      height: ${props.theme.size.base * 14}px;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
    .show-time {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      justify-content: flex-end;
      font-size: ${props.theme.size.fontSize[1]};
      line-height: ${props.theme.size.lineHeight[1]};
    }
    .on-air {
      margin-left: ${props.theme.size.base * 2}px;
    }
    .show-dj {
      display: flex;
      align-items: center;
    }
    .dj-image {
      display: block;
      flex-shrink: 0;
      margin-right: ${props.theme.size.base * 2}px;
      width: ${props.theme.size.base * 4}px;
      height: ${props.theme.size.base * 4}px;
      border-radius: ${props.theme.size.base * 5}px;
    }
  .dj-name {
    font-size: ${props.theme.size.fontSize[2]};
    line-height: ${props.theme.size.lineHeight[2]};
    font-weight: 600;
    color: ${props.theme.color.primary[50]};
    a {
      color: ${props.theme.color.foreground[100]};
      text-decoration: none;
      &:hover {
        color: ${props.theme.color.tertiary[60]};
      }
    }
  `
);

const Playlist = (props) => {
  const breakpoints = useBreakpoint();
  const [posts, setPosts] = useState([]);

  const getPlaylist = async () => {
    try {
      const userPosts = await axios.get(
        `https://kuaa-api-7xgcl.ondigitalocean.app/recentplays/150`
      );
      setPosts(userPosts.data); // set State
    } catch (err) {
      console.error(err.message);
    }
  };

  const getId = (str) => {
    return str.substring(str.lastIndexOf('/') + 1, str.length);
  };

  // Set update loop
  useEffect(() => {
    getPlaylist();
  }, []);

  function isLive(start, end) {
    var dateNow = new Date();
    var dateStart = new Date(start);
    var dateEnd = new Date(end);
    return dateNow > dateStart && dateEnd > dateNow;
  }

  const compareDatetimes = (start, duration) => {
    if (!start || !duration) {
      return;
    }
    const todayDate = new Date();
    const startDate = new Date(start);
    const timeAgo = todayDate.getTime() - startDate.getTime();

    return timeAgo / 1000;
  };

  return (
    <PlaylistStyles theme={props.theme} breakpoints={breakpoints}>
      <RouterTracker />
      <div className='body typesetter'>
        <h1 className='title'>Playlist</h1>
        {posts && (
          <div className='scroll-x'>
            <PlaylistTable
              width='100%'
              className='data-table'
              theme={props.theme}
              breakpoints={breakpoints}
            >
              <thead>
                <tr>
                  <th></th>
                  <th>Song</th>
                  <th>Artist</th>
                  <th>Release</th>
                  <th className='align-right'>Play Time</th>
                </tr>
              </thead>
              <tbody>
                {posts.map((post, index) =>
                  post.song ? (
                    <tr key={index}>
                      <td className='shrink'>
                        {post.image ? (
                          <div className='play-image'>
                            <img
                              width={props.theme.size.base * 10}
                              src={post.image}
                              alt={`${post.name}`}
                            />
                          </div>
                        ) : (
                          <div className='play-image-placeholder'>
                            {post.release ? post.release : post.artist}
                          </div>
                        )}
                      </td>
                      <td className='play-song'>{post.song}</td>
                      <td className='play-artist'>{post.artist}</td>
                      <td className='play-release'>{post.release}</td>
                      {/* <td>{post.released}</td>
                          <td>{post.label}</td>
                          <td>{post.isrc}</td> */}
                      <td className='play-time nowrap shrink align-right'>
                        {post.start && post.duration ? (
                          <>
                            {compareDatetimes(post.start, post.duration) >
                            post.duration ? (
                              formatRelative(new Date(post.end), new Date())
                            ) : (
                              <Badge>Playing Now</Badge>
                            )}
                          </>
                        ) : (
                          formatRelative(new Date(post.end), new Date())
                        )}
                      </td>
                    </tr>
                  ) : (
                    <ShowListItem
                      key={index}
                      theme={props.theme}
                      breakpoints={breakpoints}
                    >
                      <td className='shrink'>
                        <div className='show-image'>
                          <Link to={`/show?id=${post.showid}`}>
                            {post.image ? (
                              <img
                                width='90'
                                src={post.image}
                                alt={`${post.name}`}
                              />
                            ) : (
                              <img
                                width='90'
                                src={placeHolderImage}
                                alt={`${post.name}`}
                              />
                            )}
                          </Link>
                        </div>
                      </td>
                      <td className='show-title'>
                        <Link to={`/show?id=${post.showid}`}>{post.title}</Link>
                      </td>
                      <td>
                        <div className='show-dj'>
                          <img
                            width='40'
                            src={post.dj.image}
                            alt={`${post.dj.name}`}
                            className='dj-image'
                          />
                          <div className='dj-name'>{post.dj.name}</div>
                          {isLive(post.start, post.end) && (
                            <Badge className='on-air'>On Air</Badge>
                          )}
                        </div>
                      </td>
                      <td colSpan='2'>
                        <div className='show-time'>
                          <div>
                            {formatRelative(new Date(post.start), new Date())} -{' '}
                            {lightFormat(new Date(post.end), 'h:mm a')}
                          </div>
                        </div>
                      </td>
                    </ShowListItem>
                  )
                )}
              </tbody>
            </PlaylistTable>
          </div>
        )}
      </div>
    </PlaylistStyles>
  );
};

const Index = () => (
  <ThemeContext.Consumer>
    {(theme) => <Playlist theme={theme} />}
  </ThemeContext.Consumer>
);

export default Index;
